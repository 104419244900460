<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-8 order-lg-3 order-xl-1">
        <!--begin:: Widgets/Inbound Bandwidth-->
        <KTPortlet
          v-bind="{
          title: 'ھەپتە ئىچىدىكى ئەزا سىتاستىكىسى',
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x',
          headNoBorder: true,
          bodyFluid: true
          }"
        >
          <template v-slot:toolbar>
            <button @click="onClickRefreshUserStatics" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
              <i class="flaticon2-refresh-arrow"></i>
            </button>
          </template>
          <template v-slot:body>
            <Widget20_1
              @count="widgetCount"
              :title="widgetTitle"
              :update="userStaticUpdate"
              desc="نەپەر ئەزا يېڭىدىن قوشۇلدى"
            ></Widget20_1>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Inbound Bandwidth-->
        <div class="kt-space-20"></div>
        <!--begin:: Widgets/Outbound Bandwidth-->
        <KTPortlet
          v-bind="{
          title: 'ئىشلىتىش كېلىشىمنامىسى',
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x',
          headNoBorder: true,
          bodyFluid: true
          }"
        >
          <template v-slot:body>
            <div class="kt-portlet__space-x">
              <protocol-content />
            </div>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Outbound Bandwidth-->
      </div>
      <div class="col-xl-4 col-lg-6 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:title="'يېڭى ئەزالار'"
        >
          <template v-slot:toolbar>
            <button @click="refreshNewlyUser" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
              <i class="flaticon2-refresh-arrow"></i>
            </button>
          </template>
          <template v-slot:body>
            <UserList v-bind:datasrc="users" :busy="userBusy"></UserList>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UserList from "../partials/widgets/UserList";
import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
import ProtocolContent from "../components/protocol/ProtocolContent";


/**
 * Sample widgets data source
 */
import widget4 from "@/common/mock/widget-4.json";
import { GET_USER_LIST } from "../../store/types";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    UserList,
    Widget20_1,
    ProtocolContent
  },
  data() {
    return {
      widget4: widget4,
      users: [],
      userBusy: false,
      orderBusy: false,
      widgetTitle: '',
      widgetOrderTitle: '',
      userStaticUpdate: false,
      orderStaticUpdate: false
    };
  },
  created() {
    this.getUserList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "باشبەت" }]);
  },
  methods: {
    refreshNewlyUser(event) {
      this.getUserList();
    },
    getUserList(page = 1){
      this.userBusy = true;
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_USER_LIST, {page, name: ''})
          .then(response=>{
            let { data: users } = response.data;
            this.users = users;
            this.userBusy = false;
            resolve(response)
          })
          .catch(response=>{
            this.userBusy = false;
            reject(response);
          })
      })
    },
    widgetCount(e){
      this.widgetTitle = String(e);
      this.userStaticUpdate = false;
    },
    widgetOrderCount(e){
      this.widgetOrderTitle = String(e);
      this.orderStaticUpdate = false;
    },
    onClickRefreshUserStatics(){
      this.userStaticUpdate = true;
    },
    onClickRefreshOrderStatics(){
      this.orderStaticUpdate = true;
    }
  }
};
</script>
